/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable indent */

import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import { withStyles, useTheme } from '@material-ui/core/styles'
import FocusTrap from 'react-focus-trap'

import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { onboardingHsFormCollect } from '../hubspot/HubSpotFormAPI'
import { provisionAccount } from './accountCreation'
import Text from '../../common/Text'
import TextLink from '../../common/TextLink'
import Button from '../../common/Button'
import Input, { RequiredSpan } from '../Input'
import Space from '../../common/Space'
import PhoneNumberInput from '../PhoneNumberInput'
import { trackEvent } from '../../../utils/segmentTrack'
import { theme } from '../../../styles'
import { FormWrapper, StageWrapper, FormHeader } from './utils'
import { FormInner, ButtonWrapper, CloseButton, FormHeaderWrapper, InputContainer } from '../common'
import { AgencyFormFields } from './AgencyFormFields'

import CheckSite from './CheckSite'

import EyeIcon from '../../../../static/get-started-free/eye.svg'
import CrossedEyeIcon from '../../../../static/get-started-free/crossed-eye.svg'
import WarningGraphic from '../../../../static/get-started-free/warning-graphic.svg'
import checkAgencyKeywords from '../../../utils/checkAgencyKeywords'

const StyledDialog = withStyles({
  root: {
    maxWidth: '100vw',
    minHeight: '100%',
    padding: '0px 16px',
    backgroundImage: `linear-gradient(to bottom, rgba(45, 3, 99, .85) 0%, rgba(113, 25, 225, .85) 100%)`,
  },
  container: {
    padding: '32px 0px',
    borderRadius: '10px',
  },
  paper: {
    borderRadius: '10px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    maxWidth: 'unset',
    margin: '16px',
    position: 'unset',
    backgroundColor: 'transparent',
  },
  paperScrollPaper: {
    borderRadius: '10px',
    height: 'auto',
    width: 'auto',
    boxShadow: 'none',
    '@media (max-height: 900px)': {
      height: '100%',
    },
  },
  paperFullScreen: {
    borderRadius: '10px',
    maxHeight: 'calc(100% - 24px)',
  },
})(Dialog)

export const NameInputWrapper = styled(InputContainer)`
  @media (max-width: 800px) {
    flex-direction: row;
  }
`

export const ValidatedInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
`

const EyeImg = styled.img`
  width: 28px;
  margin-left: -40px;
  margin-top: 10px;
  cursor: pointer;
  z-index: 1;
`

const WarningImg = styled.img`
  position: absolute;
  left: 30%;
  top: -18px;
  width: 140px;
  z-index: 3;
`

const PasswordText = styled.label`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  grid-template-columns: auto 1fr;
  justify-items: flex-end;
  align-items: baseline;
  margin-bottom: 0px;
`

const OnboardingModal = ({ open }) => {
  const urlInputRef = React.createRef()

  const [focusStageOne, setFocusStageOne] = useState(false)
  const [stage, setStage] = useState(1)
  const [showPass, setShowPass] = useState(false)
  const [passwordWarn, setPasswordWarn] = useState(false)
  const [passwordErr, setPasswordErr] = useState(false)
  const [numWebsitesErr, setNumWebsitesErr] = useState(false)
  const [partnerName, setPartnerName] = useState(null)
  const [intlNum, setIntlNum] = useState('')
  const [formSubmitReady, setFormSubmitReady] = useState(false)
  const [emailError, setEmailError] = useState()

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    freelancerAgency: '',
    numWebsites: '',
    company: '',
    url: '',
    password: '',
    agencyKeywordsFound: '',
    form_name: 'Onboarding Modal',
  })

  const [sessionFormData, setSessionFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    freelancerAgency: '',
    numWebsites: '',
    url: '',
  })

  const MUItheme = useTheme()
  const fullScreen = useMediaQuery(MUItheme.breakpoints.down('sm'))

  const windowGlobal = typeof window !== `undefined` && window

  const updateFormData = prop => setFormData({ ...formData, ...prop })
  const updateSessionFormData = prop => setSessionFormData({ ...sessionFormData, ...prop })

  const onOpenOne = async () => {
    setFocusStageOne(true)
    const nameInput = windowGlobal && document.querySelector('#name-input')
    if (nameInput) nameInput.focus()
  }

  const onExitOne = () => {
    setFocusStageOne(false)
  }

  const onEntered = () => {
    onOpenOne()
  }

  const closeModal = () => {
    if (windowGlobal) {
      onExitOne()
      navigate(windowGlobal.location.pathname)
    }
  }

  useEffect(() => {
    const htmlElement = document.querySelector('html')
    if (open && !htmlElement.classList.contains('overflow-hidden')) {
      htmlElement.classList.add('overflow-hidden')
    } else {
      htmlElement.classList.remove('overflow-hidden')
    }
  }, [open])

  useEffect(() => {
    // reset to stage one and track modal pageview upon re-opening modal
    if (open === true) {
      setStageOne()

      if (windowGlobal) {
        const scan_url = windowGlobal.sessionStorage.getItem('scan_url')
        const hsFormData = typeof window !== 'undefined' && window.sessionStorage.getItem('hs-form-data')
        const parsedData = JSON.parse(hsFormData)
        const mappedData = mapValues(keyBy(parsedData, 'name'), 'value')
        const sessionFirstName = mappedData.firstname
        const sessionLastName = mappedData.lastname
        const sessionEmail = mappedData.email
        const sessionPhone = mappedData.phone
        const sessionAgency = mappedData.agency_or_freelancer
        const sessionAgencySize = mappedData.record_type

        if (
          scan_url ||
          sessionFirstName ||
          sessionLastName ||
          sessionEmail ||
          sessionPhone ||
          sessionAgency ||
          sessionAgencySize
        ) {
          updateFormData({
            url: scan_url,
            fullName: `${sessionFirstName || ''} ${sessionLastName || ''}`,
            email: sessionEmail,
            phone: sessionPhone,
            freelancerAgency: sessionAgency,
            numWebsites: sessionAgencySize,
          })
          updateSessionFormData({
            url: scan_url,
            fullName: `${sessionFirstName || ''} ${sessionLastName || ''}`,
            email: sessionEmail,
            phone: sessionPhone,
            freelancerAgency: sessionAgency,
            numWebsites: sessionAgencySize,
          })
          if (sessionPhone) setIntlNum(sessionPhone)
        }
      }
    }
  }, [open])

  const setStageOne = () => {
    trackEvent(`Onboarding Modal Viewed`, {
      category: 'onboarding_modal',
      current_step: '1',
      max_step: '1',
    })
    onOpenOne()

    setStage(1)
  }

  const validatePass = password => {
    const pattern = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})')
    setPasswordWarn(true)
    if (pattern.test(password) === true) {
      setPasswordWarn(false)
      setPasswordErr(false)
    }
  }

  const validateEmail = async email => {
    if (window.location.pathname === '/get-started/') {
      const invalidEmails = ['gmail.com', 'yahoo.com', 'msn.com', 'aol.com', 'hotmail.com', 'sbcglobal.com']
      let threshHold = 0
      invalidEmails.forEach(domain => {
        if (email.includes(domain)) {
          threshHold += 1
        }
      })
      if (threshHold > 0) {
        setEmailError(true)
        return
      }
    }
    setEmailError(false)
  }

  useEffect(() => {
    if (formData.numWebsites && formData.numWebsites.length > 0 && numWebsitesErr) setNumWebsitesErr(false)
  }, [numWebsitesErr, formData.numWebsites])

  useEffect(() => {
    if (formSubmitReady === true) {
      onboardingHsFormCollect(formData, intlNum)
    }
  }, [formSubmitReady, formData])

  const submit = async evt => {
    evt.preventDefault()

    const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))
    await timeout(0)

    if (emailError) {
      const emailErrAlert = windowGlobal && document.querySelector('#email-error-alert')
      if (emailErrAlert) emailErrAlert.focus()
      const emailInput = windowGlobal && document.querySelector('input[type="email"]')
      if (emailInput) emailInput.focus()
      return
    }

    if (passwordWarn) {
      setPasswordErr(true)
      const errAlert = windowGlobal && document.querySelector('#error-alert')
      if (errAlert) errAlert.focus()
      const passwordInput = windowGlobal && document.querySelector('#password-input')
      if (passwordInput) passwordInput.focus()
      return
    }

    if (formData.freelancerAgency && !formData.numWebsites) {
      setNumWebsitesErr(true)
      const numWebsiteInput = windowGlobal && document.querySelector('input[name="record_type"]')
      if (numWebsiteInput) numWebsiteInput.focus()
      return
    }

    await checkAgencyKeywords(formData.url, updateFormData)

    setFormSubmitReady(true)
    onExitOne()
    setStage(2)

    provisionAccount(setStage, setPartnerName, formData, intlNum)
  }

  return (
    <StyledDialog
      open={open}
      keepMounted
      aria-label="sign up form"
      aria-modal="true"
      role="dialog"
      maxWidth={false}
      onClose={closeModal}
      TransitionProps={{
        onEntered,
      }}
      fullScreen={fullScreen}
      BackdropProps={{ style: { opacity: 0 } }}
    >
      <FormWrapper id="form-wrapper">
        <FocusTrap onExit={onExitOne} active={focusStageOne}>
          <StageWrapper id="stage-wrapper" open={stage === 1} aria-hidden={!stage === 1} aria-labelledby="form-header">
            <CloseButton closeModal={closeModal} />
            <FormInner id="form-inner">
              <FormHeaderWrapper>
                <FormHeader />
              </FormHeaderWrapper>
              <form onSubmit={submit} id="get-started-onboard-modal-step-1">
                {!sessionFormData.fullName && (
                  <>
                    <NameInputWrapper id="name-input-wrapper">
                      <Input
                        label="Full Name"
                        placeholder=""
                        autoComplete="given-name"
                        name="full_name"
                        onChange={e => updateFormData({ fullName: e.target.value })}
                        value={formData.fullName}
                        required
                        autoCapitalize="words"
                        id="name-input"
                      />
                    </NameInputWrapper>
                    <Space height={4} />
                  </>
                )}
                {!sessionFormData.email && (
                  <>
                    <InputContainer id="email-wrapper" style={{ position: 'relative' }}>
                      <Input
                        label="Work Email"
                        placeholder=""
                        name="email"
                        inputMode="email"
                        type="email"
                        required
                        value={formData.email}
                        onChange={e => {
                          if (e.target.value.includes('@')) {
                            validateEmail(e.target.value)
                          }
                          updateFormData({ email: e.target.value })
                        }}
                        id="email-input"
                      />
                      {emailError && (
                        <Text
                          microBody
                          color="red500"
                          id="email-error-alert"
                          role="alert"
                          style={{ textAlign: 'right', position: 'absolute', right: '0', top: '10px' }}
                        >
                          Enter a valid email
                        </Text>
                      )}
                    </InputContainer>
                    <Space height={4} />
                  </>
                )}
                {!sessionFormData.phone && (
                  <>
                    <InputContainer id="phone-wrapper" style={{ marginBottom: '.5rem' }}>
                      <ValidatedInputWrapper>
                        <PhoneNumberInput
                          updateFormData={updateFormData}
                          formData={formData}
                          setIntlNum={setIntlNum}
                          id="phone-input"
                        />
                      </ValidatedInputWrapper>
                    </InputContainer>
                    <Space height={4} />
                  </>
                )}
                {windowGlobal && !windowGlobal.sessionStorage.getItem('scan_url') && (
                  <>
                    <InputContainer id="url-wrapper">
                      <Input
                        label="Website URL"
                        name="url"
                        id="url-input"
                        ref={urlInputRef}
                        onChange={e => updateFormData({ url: e.target.value })}
                        value={formData.url}
                        required
                        style={{ flex: '1' }}
                        pattern="(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
                        onInput={e => {
                          e.target.setCustomValidity('')
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity('Please enter a valid URL.')
                          }
                        }}
                      />
                    </InputContainer>
                    <Space height={4} />
                  </>
                )}
                <ValidatedInputWrapper id="password-wrapper" style={{ marginBottom: '.5rem' }}>
                  <PasswordText htmlFor="password-input">
                    <Text smallBodyHeavy>
                      Password <RequiredSpan>*</RequiredSpan>
                    </Text>
                    {passwordWarn && (
                      <Text
                        microBody
                        color="red500"
                        id="password-sub-label"
                        aria-hidden="true"
                        style={{ textAlign: 'right' }}
                      >
                        8+ characters, with a number, lower and uppercase letter
                      </Text>
                    )}
                  </PasswordText>
                  <PasswordContainer id="password-container">
                    {passwordErr && (
                      <WarningImg id="error-alert" src={WarningGraphic} alt="Correct this field" role="alert" />
                    )}
                    <Input
                      placeholder=""
                      name="password"
                      autoComplete="new-password"
                      required
                      type={showPass ? 'text' : 'password'}
                      value={formData.password}
                      onChange={e => {
                        validatePass(e.target.value)
                        updateFormData({ password: e.target.value })
                      }}
                      aria-describedby="password-sub-label"
                      id="password-input"
                      style={{ paddingRight: '48px' }}
                      requiredLabel="password"
                    />
                    <EyeImg
                      src={showPass ? EyeIcon : CrossedEyeIcon}
                      alt=""
                      role="button"
                      tabIndex="0"
                      aria-pressed={showPass ? 'true' : 'false'}
                      aria-label="Show password"
                      className="focus-outline"
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          showPass === false ? setShowPass(true) : setShowPass(false)
                        }
                      }}
                      onClick={() => {
                        showPass === false ? setShowPass(true) : setShowPass(false)
                      }}
                    />
                  </PasswordContainer>
                </ValidatedInputWrapper>
                {windowGlobal &&
                  !sessionFormData.freelancerAgency &&
                  !windowGlobal.sessionStorage.getItem('agency_viewed') && (
                    <AgencyFormFields
                      formData={formData}
                      updateFormData={updateFormData}
                      numWebsitesErr={numWebsitesErr}
                      onboarding
                    />
                  )}
                <Space height={16} />
                <ButtonWrapper>
                  <Button text="Create Account" type="submit" id="modal-submit-button" />
                </ButtonWrapper>
                <Space height={16} />
                <Text
                  tinyBody
                  center
                  color="gray700"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  By creating an account, you're agreeing to our{' '}
                  {formData.freelancerAgency && (
                    <>
                      <TextLink
                        to="/reseller-program-agreement"
                        href="/reseller-program-agreement"
                        text="Reseller Terms and Conditions"
                        color="purple500"
                        font="tinyBody"
                      />
                      {', '}
                    </>
                  )}
                  <TextLink
                    to="/terms-of-service"
                    href="/terms-of-service"
                    text="Terms of Service"
                    color="purple500"
                    font="tinyBody"
                  />
                  {formData.freelancerAgency && ','} and{' '}
                  <TextLink
                    to="/privacy-policy"
                    href="/privacy-policy"
                    text="Policies"
                    color="purple500"
                    font="tinyBody"
                  />{' '}
                </Text>
              </form>
            </FormInner>
          </StageWrapper>
        </FocusTrap>
        <CheckSite
          stage={stage}
          closeModal={closeModal}
          setStageOne={setStageOne}
          formData={formData}
          partnerName={partnerName}
        />
      </FormWrapper>
    </StyledDialog>
  )
}

export default OnboardingModal
